<template>
  <v-container
    fluid
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-col cols="12">
        <v-btn
          color="primary"
          fab
          dark
          to="/pages/usuarios/create"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="12"
      >
        <v-card>
          <v-card-title>
            Usuários
            <v-spacer />
            <v-text-field
              v-model="search"
              label="Buscar (nome, e-mail)"
              single-line
              hide-details
              class="mr-5"
              @keyup.enter="load"
            />
            <v-text-field
              v-model="codigoStyllus"
              label="Buscar (código styllus)"
              single-line
              hide-details
              type="number"
              @keyup.enter="load"
            />
            <v-btn
              color="primary"
              @click="load"
            >
              Buscar
            </v-btn>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="users"
            :options.sync="options"
            :server-items-length="total"
            :footer-props="footer_props"
            class="elevation-1"
          >
            <template v-slot:body="{ items }">
              <tbody>
                <tr
                  v-for="item in items"
                  :key="item.id"
                >
                  <td>{{ item.nome }}</td>
                  <td>{{ item.email }}</td>
                  <td>
                    <router-link
                      v-if="item.clienteId"
                      :to="`/clientes/${item.clienteId}`"
                    >
                      {{ item.codigoStyllus }}
                    </router-link>
                    <span v-else>{{ item.codigoStyllus }}</span>
                  </td>
                  <td>{{ item.perfil ? item.perfil.nome : '' }}</td>
                  <td>
                    <router-link :to="`/pages/usuarios/${item.id}`">
                      <v-icon
                        class="mr-2"
                        color="info"
                      >
                        mdi-pencil
                      </v-icon>
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  export default {
    name: 'UserList',
    data: () => ({
      search: '',
      codigoStyllus: '',
      itemselected: {},
      users: [],
      headers: [
        { text: 'Nome', sortable: false },
        { text: 'E-mail', sortable: false },
        { text: 'Código Styllus', sortable: false },
        { text: 'Tipo', sortable: false },
        { text: 'Ações', sortable: false },
      ],
      footer_props: {
        'items-per-page-options': [5, 10, 15, 20],
      },
      total: 0,
      dialog: false,
      options: {
        sortBy: [],
        sortDesc: [],
      },
      filter: {
        PerPage: 25,
        Page: 1,
        SortAscending: true,
      },
    }),
    watch: {
      options: {
        handler () {
          this.load()
        },
        deep: true,
      },
    },
    methods: {
      resetPassword (id) {
        this.$http.put('/reset-password/', { UsuarioId: id })
          .then(resp => {
            this.profiles = resp.data
            this.passwordRules = []
          })
      },
      load: function () {
        this.filter.PerPage = this.options.itemsPerPage
        this.filter.Page = this.options.page
        this.filter.Search = this.search
        this.filter.CodigoStyllus = this.codigoStyllus

        const query = JSON.stringify(this.filter)
        this.$http.get('/usuarios', { params: { query } })
          .then(resp => {
            this.users = resp.data.rows
            this.total = resp.data.total
          })
          .catch(() => {
            this.$toast.error('Falha ao realizar operação')
          })
      },
      destroy (item) {
        this.itemselected = item
        this.dialog = true
      },
      confirmDelete () {
        this.$http.delete(`/usuario/${this.itemselected.id}`)
          .then(resp => {
            this.$toast.success('Operação realizada com sucesso')
            this.dialog = false
            this.load()
          })
          .catch(() => {
            this.$toast.error('Falha ao realizar operação')
          })
      },
    },
  }
</script>

<style>
a {
  text-decoration: none;
}
.form-search {
  width: 50%;
}
</style>
